<template>
  <div class="min-h-screen h-full bg-white">
    <NuxtLayout>
      <NuxtLoadingIndicator color="#3b82f6" />
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup>
const { loggedIn } = useUserSession()

watch(loggedIn, () => {
  if (!loggedIn.value) {
    navigateTo('/login')
  }
})
</script>
